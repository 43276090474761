.left-content.banner {
  background: url(../../../public/images/ashleytestrest.png) no-repeat center;
  background-position: 80% 10px !important;
  background-size: contain;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  -ms-background-size: contain;
  background-position: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 67%;
  flex: 0 0 67%;
  max-width: 100%;
  position: relative;
  margin-top: 30px;
  margin-bottom: -17px;
}
.overlay-left.banner {
    background: rgb(247, 170, 64);
    background: linear-gradient(90deg, rgb(247 170 64 / 50%) 0%, rgb(32 62 143 / 50%) 80%, rgb(32 62 143 / 50%) 100%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    bottom: 20px;
}