/*--
Author: W3layouts
Author URL: http://w3layouts.com
License: Creative Commons Attribution 3.0 Unported
License URL: http://creativecommons.org/licenses/by/3.0/
--*/
/* reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
dl,
dt,
dd,
ol,
nav ul,
nav li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

select {
  margin: 2px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* start editing from here */
a {
  text-decoration: none;
}

.txt-rt {
  text-align: right;
}

/* text align right */
.txt-lt {
  text-align: left;
}

/* text align left */
.txt-center {
  text-align: center;
}

/* text align center */
.float-rt {
  float: right;
}

/* float right */
.float-lt {
  float: left;
}

/* float left */
.clear {
  clear: both;
}

/* clear float */
.pos-relative {
  position: relative;
}

/* Position Relative */
.pos-absolute {
  position: absolute;
}

/* Position Absolute */
.vertical-base {
  vertical-align: baseline;
}

/* vertical align baseline */
.vertical-top {
  vertical-align: top;
}

/* vertical align top */
nav.vertical ul li {
  display: block;
}

/* vertical menu */
nav.horizontal ul li {
  display: inline-block;
}

/* horizontal menu */
img {
  max-width: 100%;
}

/*end reset*/
body {
  font-size: 100%;
  font-family: "Poppins", sans-serif;
}

/*--header--*/
h1 {
  font-size: 2em;
  color: #fff;
  letter-spacing: 0px;
  font-weight: 600;

  display: inline-block;
}

h1 span {
  margin-right: 10px;
}

p.help.is-danger {
  color: red;
}

.form-style-agile {
  color: red;
}

/*--//header--*/
/* content */
/* left content */
.main-w3ls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.left-content {
  /* background: url(../public/images/2.jpg) no-repeat center; */
  background: url(../public/images/ashleytestrest.png) no-repeat center;
  background-position: 50% 115px !important;
  background-size: contain;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  -ms-background-size: contain;
  background-position: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 67%;
  flex: 0 0 67%;
  max-width: 67%;
  position: relative;
}

.overlay-left {
  /* background: rgb(167, 70, 54);
  background: linear-gradient(90deg, rgba(167, 70, 54, 1) 0%, rgba(161, 187, 210, 1) 80%, rgba(147, 180, 215, 1) 100%);
  */
  background: rgb(247, 170, 64);
  background: linear-gradient(
    90deg,
    rgb(247 170 64 / 50%) 0%,
    rgb(32 62 143 / 50%) 80%,
    rgb(32 62 143 / 50%) 100%
  );
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.w3ls-content {
  padding: 5vw;
  position: relative;
  margin-top: -30px;
}

/* navigation */
ul.nav-w3ls {
  margin-top: 7em;
}

ul.nav-w3ls li {
  display: inline-block;
}

ul.nav-w3ls li a {
  color: #fff;
  font-size: 15px;
  letter-spacing: 1px;
  margin: 0 20px;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

ul.nav-w3ls li a:hover {
  opacity: 0.7;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

ul.nav-w3ls li:nth-child(1) a,
ul.nav-w3ls li:nth-child(5) a,
ul.nav-w3ls li:nth-child(3) a {
  margin: 0;
}

/* //navigation */
/* button */
a.button-w3ls span {
  background: #000;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 2.5;
  font-size: 20px;
  margin-top: 1em;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

a.button-w3ls span:hover {
  background: #203e8f !important;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

/* //button */
/* //left content */
/* right content */
.right-form-agile {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33%;
  flex: 0 0 33%;
  max-width: 33%;
}

.sub-main-w3 {
  padding: 30px 40px;
  padding-top: 10px;
  background: #0e0e0e;
  min-height: calc(105vh - 40px - 95px);
}

.sub-main-w3.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 40px;
}

.w3ls-content h2 {
  font-size: 50px;
  color: #fff;
  font-weight: 800;
}

.sub-main-w3 h3 {
  color: #fff;
  font-size: 32px;
  letter-spacing: 1px;
  font-weight: 600;
}

.sub-main-w3 h5 {
  color: #fff;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: 500;
  margin: 10px 0 4px;
}

.sub-main-w3 p {
  color: #d8d6d6;
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 1.8em;
}

.sub-main-w3 label {
  color: #fff;
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 8px;
  display: inline-block;
}

.pom-agile {
  padding: 12px 15px;
  background: #fff;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: -webkit-box;
  display: -moz-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  flex-basis: 100%;
}

.pom-agile span {
  color: #203e8f;
  flex-basis: 4%;
  -webkit-flex-basis: 4%;
  margin-right: 8px;
}

/* form */
.pom-agile input[type="text"],
.pom-agile select,
.pom-agile input[type="number"],
.pom-agile input[type="email"],
.pom-agile input[type="password"] {
  outline: none;
  font-size: 14px;
  border: none;
  background: transparent;
  color: #000;
  letter-spacing: 1px;
  flex-basis: 95%;
  -webkit-flex-basis: 95%;
}

a.logout-btn {
  background-color: #203e8f;
  color: #fff;
  padding: 10px;
}

a.logout-btn:hover {
  background: #f7aa40;
  transition: 0.5s all;
}

.sub-main-w3 input[type="submit"] {
  color: #fff;
  background: #203e8f;
  border: none;
  padding: 12px 0;
  margin-top: 1.3em;
  outline: none;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 1px;
  border-radius: 4px;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

.sub-main-w3 input[type="submit"]:hover {
  background: #f7aa40;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

/* //form */
/* checkbox */
.sub-agile {
  float: left;
}

.sub-agile input[type="checkbox"] {
  display: none;
}

.sub-agile input[type="checkbox"] + label {
  position: relative;
  padding-left: 25px;
  display: inline-block;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.sub-agile input[type="checkbox"] + label span:first-child {
  width: 13px;
  height: 13px;
  display: inline-block;
  border: 1px solid #fff;
  position: absolute;
  top: 5px;
  left: 2px;
}

.sub-agile input[type="checkbox"]:checked + label span:first-child:before {
  content: "";
  background: url(../public/images/tick.png) no-repeat;
  position: absolute;
  left: 2px;
  top: 1px;
  font-size: 10px;
  width: 10px;
  height: 10px;
}

.sub-w3l a {
  float: right;
  font-size: 0.9em;
  color: #fff;
  letter-spacing: 1px;
}

/* //checkbox */
/* placeholder-color */
::-webkit-input-placeholder {
  color: #8a8989;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #8a8989;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #8a8989;
}

:-ms-input-placeholder {
  color: #8a8989;
}

/* //placeholder-color */
/* social icons */
.w3ls-social h3 {
  color: #fff;
  letter-spacing: 2px;
  font-size: 20px;
  font-weight: 500;
  margin: 1.5em 0 1em;
}

.w3ls-social {
  text-align: center;
}

.social-nav li {
  display: inline-block;
}

.social-nav li:nth-child(2),
.social-nav li:nth-child(3) {
  margin: 0 0.5em;
}

.social-nav a {
  display: inline-block;
  float: none;
  width: 30px;
  height: 30px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  background: #000;
  position: relative;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.social-nav a:hover {
  opacity: 0.8;
}

a.twitter {
  background: #00acee;
}

a.facebook {
  background: #3b5998;
}

a.instagram {
  background: #f77737;
}

a.pinterest {
  background: #bd081c;
}

.social span {
  color: #fff;
  font-size: 12px;
  line-height: 30px;
}

/* //social icons */
/* //right content */
/* //content */
/* copyright */
.copyright p {
  color: #fff;
  letter-spacing: 2px;
  font-size: 15px;
  margin: 1.5vw;
  text-align: right;
}

.copyright p a {
  color: #203e8f;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

.copyright p a:hover {
  color: #fff;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
}

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #0000009e;
  z-index: 1111111111111111;
}

.loader.in {
  display: flex;
}

.loader img {
  width: 100px;
}

.loader .main-spin {
  width: 70px;
  height: 70px;
  border-color: #b51019;
  border-right-color: transparent;
}

span.spinner-border.spinner-border-sm2 {
  position: absolute !important;
  right: 12% !important;
  z-index: 1 !important;
  top: 49% !important;
}

span.spinner-border.spinner-border-sm3 {
  position: absolute !important;
  right: 12% !important;
  z-index: 1 !important;
  top: 81% !important;
}

span.spinner-border.spinner-border-smSplit1 {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

span.spinner-border.spinner-border-smSplit2 {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
  top: 81% !important;
}

.spinner-border-sm2 {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.spinner-border-sm3 {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.select-leading span.spinner-border.spinner-border-sm {
  position: absolute;
  right: 35px;
  z-index: 1;
  top: 20px;
}

.prfil-set button:not(:disabled) .spinner-border-sm,
.prfil-set [type="button"]:not(:disabled) .spinner-border-sm,
.prfil-set [type="reset"]:not(:disabled) .spinner-border-sm,
.prfil-set [type="submit"]:not(:disabled) .spinner-border-sm {
  display: none;
}

.prfil-set button:disabled,
.prfil-set [type="button"]:disabled .spinner-border-sm,
.prfil-set [type="reset"]:disabled .spinner-border-sm,
.prfil-set [type="submit"]:disabled .spinner-border-sm {
  display: inline-block;
  margin-right: 5px;
}
.font-22 {
  font-size: 22px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
button {
  background-color: #203e8f;
  color: #fff;
  /* width: 76px; */
}
button:hover {
  background: #f7aa40;
  transition: 0.5s all;
}
button.btn.btn-primary.w-100 {
  background-color: #203e8f;
  color: #fff;
  margin-top: 30px;
}
h1.prf-hed {
  color: #203e8f;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
}
.alert.danger-alert {
  font-size: 14px !important;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 0 15px 5px #ccc;
  background-color: #fff;
  border-left: 4px solid #b51019;
  color: #b51019;
  font-size: 14px;
}
button.btn.btn-primary.w-100:hover {
  background: #f7aa40;
  border: 1px solid #f7aa40;
  transition: 0.5s all;
}
.form-box {
  background-color: #fff;
  box-shadow: 0 0 14px 0 #929292;
  padding: 30px;
  margin-bottom: 30px;
}

/* //copyright */
/* responsive */
@media (min-width: 320px) and (max-width: 768px) {
  li.nav-item.store {
    margin-bottom: 15px;
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .left-content.banner {
    margin-top: 0px !important;
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .w3ls-content {
    padding: 5vw;
    position: relative;
    margin-top: 0px;
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .overlay-left.banner {
    bottom: 0px !important;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .left-content {
    background-position: 50% 17px !important;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }
}

@media (min-width: 767px) and (max-width: 900px) {
  .left-content {
    background-position: 90% 17px !important;
  }
}

@media (max-width: 1080px) {
  .left-content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }

  .right-form-agile {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

  .copyright p {
    font-size: 14px;
  }

  h1 a {
    font-size: 1.8em;
  }

  .w3ls-content h2 {
    font-size: 44px;
  }

  .sub-main-w3 h3 {
    font-size: 28px;
  }

  .sub-main-w3 h5 {
    font-size: 18px;
  }

  .sub-main-w3 p {
    font-size: 13px;
    margin-bottom: 1.5em;
  }

  .sub-main-w3 label {
    font-size: 13px;
  }

  .sub-agile input[type="checkbox"] + label {
    font-size: 14px;
  }

  h1 a {
    margin-bottom: 8vw;
  }

  .copyright p {
    margin: 4vw 1.5vw;
  }
}

@media (max-width: 900px) {
  .copyright p {
    padding: 4vw 1.5vw;
    margin: 0;
  }

  .left-content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 100%;
    max-width: 100%;
    /* order: 2; */
  }

  .right-form-agile {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
  }

  .sub-main-w3 {
    height: auto;
    padding: 5vw 10vw;
  }

  .w3ls-content h2 {
    font-size: 40px;
  }
}

@media (max-width: 480px) {
  .w3ls-social h3 {
    font-size: 20px;
    margin: 1.5em 0 0.6em;
  }

  .w3ls-content h2 {
    font-size: 34px;
  }

  ul.nav-w3ls li a {
    font-size: 14px;
  }

  .copyright p {
    font-size: 13px;
  }

  ul.nav-w3ls {
    margin-top: 4em;
  }
}

@media (max-width: 440px) {
  ul.nav-w3ls li a {
    margin: 0 11px;
  }
}

@media (max-width: 414px) {
  h1 a {
    font-size: 1.6em;
  }

  .w3ls-content h2 {
    font-size: 30px;
  }

  ul.nav-w3ls li a {
    margin: 0 18px;
  }
}

@media (max-width: 384px) {
  .sub-agile input[type="checkbox"] + label {
    font-size: 13px;
  }

  h1 a {
    font-size: 1.4em;
  }

  .w3ls-content h2 {
    font-size: 28px;
  }
}

@media (max-width: 375px) {
  .sub-main-w3 h3 {
    font-size: 25px;
  }

  .sub-main-w3 h5 {
    font-size: 16px;
  }

  .sub-main-w3 p {
    font-size: 12px;
  }

  .pom-agile input[type="text"],
  .pom-agile input[type="email"],
  .pom-agile input[type="password"] {
    font-size: 13px;
  }
}

@media (max-width: 320px) {
  ul.nav-w3ls {
    margin-top: 3em;
  }

  h1 a {
    font-size: 1.2em;
  }

  .w3ls-content h2 {
    font-size: 26px;
  }

  .sub-main-w3 h5 {
    font-size: 14px;
  }
}

/* //responsive */

.css-1jqq78o-placeholder {
  color: #212529 !important;
}

.reverse-bg {
  background-color: #f7aa40 !important;
  border: 1px solid #f7aa40 !important;
}
.reverse-bg:hover {
  background-color: #203e8f !important;
  border: 1px solid #203e8f !important;
}
